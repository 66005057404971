import { useConfig } from '@/context/config'
import { colors } from '@/styles/colors'
import { breakpoints } from '@/styles/cs/styleConstants'
import {
  COUPLE_GUEST_LIST_UP_EXTERNAL_WEB_URL,
  CS_EXTERNAL_WEB_URL,
} from '@/utils/common/externalWebURL'
import { css } from '@emotion/react'
import Image from 'next/image'

export default function Footer() {
  const { config } = useConfig()
  return (
    <footer css={styles.footer}>
      <div css={styles.row}>
        <div css={styles.linkArea}>
          <div css={[styles.links, styles.firstLinks]}>
            <a href={CS_EXTERNAL_WEB_URL(config).FAQ} target="_blank" rel="noopener noreferrer">
              よくある質問・お問い合わせ
            </a>
            <a
              href={COUPLE_GUEST_LIST_UP_EXTERNAL_WEB_URL(config).SERVICE_TERMS}
              target="_blank"
              rel="noopener noreferrer"
            >
              ゲストリストアップ機能利用上の留意事項
            </a>
          </div>
          <div css={styles.links}>
            <a
              href={CS_EXTERNAL_WEB_URL(config).MEMBERSHIP_AGREEMENT}
              target="_blank"
              rel="noopener noreferrer"
            >
              ID・会員規約
            </a>
            <a
              href={CS_EXTERNAL_WEB_URL(config).PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            >
              プライバシーポリシー
            </a>
            <a
              href={CS_EXTERNAL_WEB_URL(config).SPECIFIED_COMMERCIAL_TRANSACTION}
              target="_blank"
              rel="noopener noreferrer"
            >
              特定商取引に基づく表示
            </a>
          </div>
        </div>
        <div css={styles.recruit}>
          <a
            href={CS_EXTERNAL_WEB_URL(config).RECRUIT}
            target="_blank"
            rel="noopener noreferrer"
            css={styles.recruitLink}
          >
            <Image src="/images/recruit-logo.png" width={100} height={26} layout={'fixed'} />
            <div css={styles.copyright}>(C) Recruit Co., Ltd.</div>
          </a>
        </div>
      </div>
    </footer>
  )
}

const styles = {
  footer: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    /** padding-rightだけ中のリンクで合計24pxのpaddingとmarginを取っているため、40-24pxの値を入れる */
    padding: 40px 16px 40px 40px;
    margin-top: auto;
    background: ${colors.COLOR_F7F8FA};
    @media (max-width: ${breakpoints.sp}px) {
      /** padding-topだけ中のリンクでmarginが12px取られているため、28-12pxの値を入れる */
      padding: 16px 24px 24px 24px;
    }
  `,
  row: css`
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: flex-end;
    @media (max-width: ${breakpoints.sp}px) {
      flex-direction: row;
    }
  `,
  recruit: css`
    padding: 0 24px 0 0;
    margin: 0 auto 0 0;
    @media (max-width: ${breakpoints.sp}px) {
      margin: 32px auto 0 0;
    }
  `,
  recruitLink: css`
    display: flex;
    align-items: flex-end;
    text-decoration-line: none;
  `,
  linkArea: css`
    display: flex;
    flex-direction: row;
    @media (max-width: ${breakpoints.sp}px) {
      flex-direction: column;
    }
  `,
  links: css`
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    a {
      padding: 0 12px;
      margin: 12px 0 0 0;
      font-size: 11px;
      font-weight: 500;
      line-height: 100%;
      color: ${colors.COLOR_4B4B4B};
      text-decoration-line: none;
      letter-spacing: 0.5px;
      border-right: 1px solid rgba(60, 60, 67, 0.1);
    }
    a:first-child {
      border-left: 1px solid rgba(60, 60, 67, 0.1);
    }
    a:last-child {
      margin-right: 12px;
      border-right: none;
    }
    @media (max-width: ${breakpoints.sp}px) {
      a:first-child {
        border-left: none;
      }
    }
  `,
  firstLinks: css`
    a:first-child {
      border-left: none;
    }
    @media (max-width: ${breakpoints.sp}px) {
      a:last-child {
        border-right: 1px solid rgba(60, 60, 67, 0.1);
      }
    }
  `,
  copyright: css`
    margin-left: 10px;
    font-size: 10px;
    font-weight: 700;
    line-height: 100%;
    color: ${colors.COLOR_435B67};
    text-align: right;
    letter-spacing: 0.5px;
  `,
}
