import { ErrorHead } from '@/components/common/commonHead'
import { loggingCoupleGuestListUpPageView } from '@/components/common/googleTagManager'
import ServerErrorMessageComponent from '@/components/couple/common/ServerErrorMessageComponent'
import { ErrorLayout } from '@/components/couple/cs_guest_list_up/ErrorLayout'
import { useGuestListUpCoupleUserContext } from '@/context/cs_guest_list_up/session'
import { useEffect } from 'react'

export default function ServerError({ original_screen_name = 'cs_GL_error_500' }) {
  return (
    <ErrorLayout>
      <ServerErrorPage original_screen_name={original_screen_name} />
    </ErrorLayout>
  )
}

type ServerErrorPageProps = {
  original_screen_name: string
}
const ServerErrorPage = ({ original_screen_name }: ServerErrorPageProps) => {
  const { user } = useGuestListUpCoupleUserContext()
  useEffect(() => {
    loggingCoupleGuestListUpPageView({
      original_screen_name,
      guestlist_cs_userid: user?.coupleUUID,
    })
  }, [])
  return (
    <>
      <ErrorHead />
      <ServerErrorMessageComponent />
    </>
  )
}
