import {
  ClCustomerNotInvitedError,
  ClCustomerNotStartedError,
  ClNetworkError,
  ClPageError,
  ClServerError,
} from '@/components/cl/errorLayout'
import CsGuestListUpNetworkErrorPage from '@/components/couple/cs_guest_list_up/NetworkError'
import CsGuestListUpNotFoundPage from '@/components/couple/cs_guest_list_up/NotFound'
import CsGuestListUpOtherError from '@/components/couple/cs_guest_list_up/OtherError'
import CsGuestListUpServerErrorPage from '@/components/couple/cs_guest_list_up/ServerError'
import CsNetworkErrorPage from '@/components/cs/network_error'
import OtherError from '@/components/cs/other_error'
import CsNotFoundPage from '@/components/cs/page_error'
import CsServerErrorPage from '@/components/cs/server_error'
import { EntryAllClientAlreadyRegisteredError } from '@/components/entry/error/entryAllClientAlreadyRegisteredError'
import EntryTargetClientNotFoundError from '@/components/entry/error/entryTargetClientNotFoundError'
import EntryTargetHoujinError from '@/components/entry/error/entryTargetHoujinError'
import {
  EntryNetworkError,
  EntryPageError,
  EntryServerError,
} from '@/components/entry/error/errorLayout'
import GuestNetworkError from '@/components/guest/errors/networkError'
import GuestPageError from '@/components/guest/errors/pageError'
import GuestServerError from '@/components/guest/errors/serverError'
import NotFoundPage from '@/pages/404'
import Error from 'next/error'
import React from 'react'

type DefaultErrorType = {
  statusCode: number
  errorCode?: string
}

export default function DefaultError({ statusCode, errorCode }: DefaultErrorType) {
  if (process.browser) {
    const { pathname } = window.location

    if (pathname.startsWith('/cl')) {
      const pageErrorCode = ['NOT_FOUND', 'CL_FORBIDDEN']
      const serverErrorCode = [500, 502, 503]
      const authErrorCode = [401, 403]
      const customerNotStartedErrorCode = [
        'CL_CUSTOMER_PARTY_NOT_REGISTERED',
        'CL_CUSTOMER_SERVICE_NOT_REGISTERED',
      ]

      if (!window.navigator.onLine) {
        return <ClNetworkError />
      }
      if (authErrorCode.includes(statusCode)) {
        return <></>
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2345 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
      if (statusCode === 404 || pageErrorCode.includes(errorCode)) {
        return <ClPageError />
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2345 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
      if (customerNotStartedErrorCode.includes(errorCode)) {
        return <ClCustomerNotStartedError />
      }
      if (errorCode === 'CL_CUSTOMER_NOT_INVITED') {
        return <ClCustomerNotInvitedError />
      }
      if (serverErrorCode.includes(statusCode)) {
        return <ClServerError />
      }
    }
    if (pathname.startsWith('/cs_guest_list_up')) {
      const guestListUpCsErrorPage = handleCsGuestListUpErrorPage(statusCode, errorCode)

      if (guestListUpCsErrorPage != null) {
        return guestListUpCsErrorPage
      }
    }
    if (pathname.startsWith('/cs') || pathname.startsWith('/ams_web')) {
      const csErrorPage = handleCsErrorPage(statusCode, errorCode)

      if (csErrorPage != null) {
        return csErrorPage
      }
    } else if (pathname.startsWith('/guest')) {
      const serverErrorCode = [500, 502, 503]
      if (!window.navigator.onLine) {
        return <GuestNetworkError />
      }
      if (errorCode === 'NOT_FOUND') {
        return <GuestPageError />
      }
      if (serverErrorCode.includes(statusCode)) {
        return <GuestServerError />
      }
    } else if (pathname.startsWith('/entry')) {
      const pageErrorCode = ['NOT_FOUND', 'CL_FORBIDDEN']
      const serverErrorCode = [500, 502, 503]
      const authErrorCode = [401, 403]

      if (!window.navigator.onLine) {
        return <EntryNetworkError />
      }
      if (authErrorCode.includes(statusCode)) {
        window.location.href = '/entry'
        return <></>
      }

      // `entryCommonErrorCode` にあるものを共通でハンドリングする
      switch (errorCode) {
        case 'ENTRY_NO_HOUJIN_TARGET':
          return <EntryTargetHoujinError />
        case 'ENTRY_HAS_MULTIPLE_HOUJIN_TARGET':
          return <EntryTargetHoujinError />
        case 'ENTRY_NO_MADOGUCHI_TARGET':
          return <EntryTargetClientNotFoundError />
        case 'ENTRY_ALL_MADOGUCHI_ALREADY_REGISTERED':
          return <EntryAllClientAlreadyRegisteredError />
        default:
          break
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2345 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
      if (statusCode === 404 || pageErrorCode.includes(errorCode)) {
        return <EntryPageError />
      }
      if (serverErrorCode.includes(statusCode)) {
        return <EntryServerError />
      }
    }
  }
  if (statusCode === 404) {
    return <NotFoundPage />
  }
  return <Error statusCode={statusCode} />
}

/**
 * 申し込み情報で全ての画面共通で発生し得るエラー
 * 画面個別でもエラーをハンドリングする場合には、ここのエラーコードに該当するものだけ
 * `setError` でハンドリングするようにする
 */
export const entryCommonErrorCode = [
  'ENTRY_NO_HOUJIN_TARGET',
  'ENTRY_HAS_MULTIPLE_HOUJIN_TARGET',
  'ENTRY_NO_MADOGUCHI_TARGET',
  'ENTRY_ALL_MADOGUCHI_ALREADY_REGISTERED',
]

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7006 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
const handleCsErrorPage = (statusCode, errorCode) => {
  if (!window.navigator.onLine) {
    return <CsNetworkErrorPage />
  }
  switch (errorCode) {
    case 'NOT_FOUND':
      return <CsNotFoundPage />
      break
    case 'INTERNAL_SERVER_ERROR':
      return <CsServerErrorPage />
      break
    case 'CS_UPDATE_PARTY_INVITATION_ALREADY_EXIST':
    case 'CS_UPDATE_INVITATION_NOT_UPDATABLE':
      return (
        <OtherError
          title={'想定外のエラーが\n発生しました'}
          sub_title={[
            '処理中に問題が発生しました。',
            '恐れ入りますが、トップ画面から',
            '再度操作してください。',
          ].join('\n')}
          action_label={'パーティトップへ'}
          action_ref={'/cs/'}
          original_screen_name={'cs_error_invitation_already_exist'}
        />
      )
      break
    case 'CS_INVITE_PARTNER_NOT_FOUND':
      return (
        <OtherError
          title={'招待リンクの\n有効期限が切れています'}
          sub_title={'パートナーの方に\n招待メールの再送を依頼してください。'}
          original_screen_name={'cs_error_partner_verification_url_not_found'}
        />
      )
    default:
      break
  }
  switch (statusCode) {
    case 401:
    case 403:
      window.location.href = '/cs/login'
      // 遷移完了までは空コンポーネントを表示
      return <></>
      break
    case 404:
      return <CsNotFoundPage />
      break
    case 500:
    case 502:
    case 503:
      return <CsServerErrorPage />
      break
    default:
      break
  }
  return null
}

const handleCsGuestListUpErrorPage = (statusCode: number, errorCode?: string) => {
  if (!window.navigator.onLine) {
    return <CsGuestListUpNetworkErrorPage />
  }
  switch (errorCode) {
    case 'CS_GUEST_LIST_UP_INVITE_PARTNER_NOT_FOUND':
      return (
        <CsGuestListUpOtherError
          title={'招待リンクの\n有効期限が切れています'}
          sub_title={'パートナーの方に\n招待メールの再送を依頼してください。'}
          original_screen_name={'cs_GL_error_partner_url_not_found'}
        />
      )
      break
    case 'NOT_FOUND':
      return <CsGuestListUpNotFoundPage />
      break
    case 'INTERNAL_SERVER_ERROR':
      return <CsGuestListUpServerErrorPage />
      break
    default:
      break
  }
  switch (statusCode) {
    case 401:
    case 403:
      window.location.href = '/cs_guest_list_up/login'
      // 遷移完了までは空コンポーネントを表示
      return <></>
    case 404:
      return <CsGuestListUpNotFoundPage />
    case 500:
    case 502:
    case 503:
      return <CsGuestListUpServerErrorPage />
    default:
      break
  }
  return null
}
