/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GetGuestsForCsvResponseGuest
 */
export interface GetGuestsForCsvResponseGuest {
  /**
   *
   * @type {string}
   * @memberof GetGuestsForCsvResponseGuest
   */
  guestUUID: string
  /**
   *
   * @type {string}
   * @memberof GetGuestsForCsvResponseGuest
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof GetGuestsForCsvResponseGuest
   */
  toBeInvited: boolean
  /**
   *
   * @type {number}
   * @memberof GetGuestsForCsvResponseGuest
   */
  readableGuestID: number
  /**
   *
   * @type {string}
   * @memberof GetGuestsForCsvResponseGuest
   */
  category: GetGuestsForCsvResponseGuestCategory
  /**
   *
   * @type {string}
   * @memberof GetGuestsForCsvResponseGuest
   */
  community: GetGuestsForCsvResponseGuestCommunity
}

/**
 * @export
 * @enum {string}
 */
export enum GetGuestsForCsvResponseGuestCategory {
  family = 'FAMILY',
  school = 'SCHOOL',
  workplace = 'WORKPLACE',
  others = 'OTHERS',
}
/**
 * @export
 * @enum {string}
 */
export enum GetGuestsForCsvResponseGuestCommunity {
  parentAndSiblings = 'PARENT_AND_SIBLINGS',
  paternalRelatives = 'PATERNAL_RELATIVES',
  maternalRelatives = 'MATERNAL_RELATIVES',
  neighbors = 'NEIGHBORS',
  kindergarten = 'KINDERGARTEN',
  elementarySchool = 'ELEMENTARY_SCHOOL',
  juniorHighSchool = 'JUNIOR_HIGH_SCHOOL',
  highSchool = 'HIGH_SCHOOL',
  university = 'UNIVERSITY',
  bosses = 'BOSSES',
  seniors = 'SENIORS',
  colleagues = 'COLLEAGUES',
  juniors = 'JUNIORS',
  previousWorkplace = 'PREVIOUS_WORKPLACE',
  partTimeJob = 'PART_TIME_JOB',
  hobby = 'HOBBY',
  others = 'OTHERS',
}
