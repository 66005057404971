import { RPCError } from '@/data/rpc'
import { createContext, useContext } from 'react'

export type GuestListUpCoupleUser = {
  coupleUUID: string
  partnerUUID: string
}

type GuestListUpCoupleUserContextType = {
  user: GuestListUpCoupleUser | null
  error: RPCError | null
}

const GuestListUpCoupleUserContext = createContext<GuestListUpCoupleUserContextType>({
  user: null,
  error: null,
})

export const GuestListUpCoupleUserContextProvider: React.FC<GuestListUpCoupleUserContextType> = ({
  user,
  error,
  children,
}) => (
  <GuestListUpCoupleUserContext.Provider value={{ user, error }}>
    {children}
  </GuestListUpCoupleUserContext.Provider>
)

export const useGuestListUpCoupleUserContext = () => useContext(GuestListUpCoupleUserContext)
